/**
 * @param {HTMLCollection} cards
 */
function closeCards(cards) {
  for (const card of cards) {
    card.classList.add("card--closed");
    for (const img of card.querySelectorAll("img")) {
      img.loading = "lazy";
    }
  }
}

/**
 * @param {HTMLElement} card
 */
function openCard(card) {
  card.classList.remove("card--closed");
  for (const img of card.querySelectorAll("img")) {
    img.loading = "eager";
  }
}

function getSelectedColor(e) {
  const { hash } = new URL(e.target.href);
  return hash.substring(1);
}

/**
 * @param {Window} globalScope
 * @returns {ColorPicker}
 */
export function defColorPicker({
  document,
  HTMLElement,
  customElements,
  requestAnimationFrame,
}) {
  class ColorPicker extends HTMLElement {
    static register(tagName = "color-picker") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    async connectedCallback() {
      const cards = document.querySelectorAll(".color-palettes .card");
      requestAnimationFrame(() => closeCards(cards));

      this.addEventListener("click", (e) => {
        if (!e.target.href) return;
        e.preventDefault();
        closeCards(cards);
        const selectedColor = getSelectedColor(e);
        const el = document.getElementById(selectedColor);
        // TODO: view transitions
        if (requestAnimationFrame) {
          requestAnimationFrame(() => openCard(el));
        } else {
          openCard(el);
        }
      });
    }
  }

  return ColorPicker;
}

if (window?.customElements) {
  defColorPicker(window).register();
}
